.preview-list {
    >li {
        padding-bottom: 30px;
    }
    p {
        padding-top: 8px;
        color: $color-dark;
        word-break: break-word;
    }
}

.price-section {
    padding: 40px 0;
    border-top: 1px solid $color-text-primary-light;
    border-bottom: 1px solid $color-text-primary-light;
    margin-bottom: 50px;

    h3 {
        text-align: center;
        padding-bottom: 40px;
    }
}
.price-list {

    li {
        display: flex;
        &:not(:last-child) {
            padding-bottom: 25px;
        }
    }
    p {
        min-width: 200px;
        padding-right: 10px;
    }
}
