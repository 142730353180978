html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video, button {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

article, aside, details, figcaption, figure, dialog,
footer, header, hgroup, menu, nav, section, main {
  display: block;
}

ul, ul li {
  list-style: none;
}

blockquote, q {
  quotes: none;

  &:before, &:after {
    content: none;
  }
}

a {
  text-decoration: none;
  font-size: 100%;
  color: inherit;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * Paul Irish box sizing reset so all elements have broder-box.
 */
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
  font-family: inherit;
}

/**
 * custom smarty resets
 */
a {
  text-decoration: none;
}

button, input, a, textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input {
    &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 30px #fff inset !important;
    }
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
