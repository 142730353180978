@mixin font-face($font-name, $font-face-name, $font-style, $font-weight, $woff2: false) {
    $file-path: $fonts-folder +'/'+ $font-name;
    @font-face {
        font-family: $font-face-name;
        font-style: $font-style;
        font-weight: $font-weight;
        font-display: block;
        @if($woff2) {
            unicode-range: U+000-5FF;
            src: local($font-face-name),
            url($file-path + ".woff2") format('woff2'),
            url($file-path + ".woff") format('woff'),
            url($file-path + ".ttf") format('truetype'),
            url($file-path + ".eot") format('embedded-opentype');
        } @else {
            src: local($font-face-name),
            url($file-path + ".woff") format('woff'),
            url($file-path + ".ttf") format('truetype'),
            url($file-path + ".eot") format('embedded-opentype');
        }

    }
}


@mixin position($position: absolute, $top: null, $right: null, $bottom: null, $left: null) {
    position: $position;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

@mixin ellipsis($width) {
    max-width: $width;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin line-clamp($count) {
    display: -webkit-box;
    -webkit-line-clamp: $count;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

// Alignment

@mixin YAlign {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

@mixin XAlign {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

@mixin XYAlign {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// Background image

@mixin bgImage {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

@mixin selection {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
