.react-responsive-modal-container {
    overscroll-behavior: contain;
}
.react-responsive-modal-modal {
    padding: 64px 87px 50px 87px;
    width: 100%;
    max-width: 680px;
    overflow-y: visible;

    &.large-modal {
        max-width: 964px;
        width: 100%;
    }

    .modal-title {
        margin-bottom: 44px;
    }

    .modal-text {
        margin-bottom: 30px;
    }

    .modal-footer {
        padding-top: 50px;
        display: flex;
        justify-content:  flex-end;
        border-top: 1px solid $color-text-primary;

        &.side-aligned {
            justify-content: space-between;
        }
    }

    .react-responsive-modal-closeButton {
        border-radius: $br-circle;
        overflow: hidden;
        width: 24px;
        height: 24px;
        background-color: $color-primary-hover;
        top: -12px;
        right: -12px;

        &::after {
            content: '✖';
            font-size: 17px;
            border-radius: $br-circle;
            overflow: hidden;
            background-color: $color-primary-hover;
            color: $color-text-primary;
            position: absolute;
            font-weight: 700;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        svg {
            display: none;
        }
    }

    .section-for-student {
        margin: 0 -87px;
    }
}
.react-responsive-modal-overlay {
    background: rgba($color-text-primary, .5);
}
