.data-list {

    > li {
        display: flex;
        align-items: center;
        margin-bottom: 32px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &.space-b-sm {
        margin-bottom: 26px;
    }

    .text-label {
        min-width: 175px;
        padding-right: 20px;

        &.sm {
           min-width: 98px;
            font-size: $fz-normal;
            font-weight: 700;
        }
    }

    .text-info {
        font-size: $fz-xs;
        color: $color-text-primary-light;
        max-width: 420px;
        margin-top: 15px;
    }

    .item-right-space {
        margin-left: 175px;
    }

    .text-value {
        color: $color-secondary-2;

        &.disabled {
            color: $color-disabled;
        }
    }
}

.password-text {
    letter-spacing: 2px;
    font-size: 23px;
}

.li-space {
    padding-bottom: 50px;

    &.pb-7 {
        padding-bottom: 28px;
    }

   > li {
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.li-space-xxs {

    > li {
        margin-bottom: 4px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}


.list-bordered {

    /*&.no-border-last-child {
        > li {
            &:last-child {
                border-bottom: none;
            }
        }
    }*/

    > li {
        border-top: 1px solid $color-text-primary-light;
        margin-top: 30px;
        padding-top: 30px;

        &:last-child {
            border-bottom: 1px solid $color-text-primary-light;
            margin-bottom: 0;
            padding-bottom: 30px;
        }
    }
}
.react-responsive-modal-modal {
    .list-bordered {
        > li {
            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
            }
        }
    }
}

.load-button {
    margin-top: 50px;
}

.list-x-border {

    > li {
        border-right: 1px solid $color-text-primary-light;
        margin-right: 24px;
        padding-right: 24px;

        &:last-child {
            border: 0;
            padding-right: 0;
            margin-right: 0;
        }
    }
}
