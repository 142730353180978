/*overflows*/

.overflow-hidden {
    overflow: hidden;
}

/*text decoration*/
.underline {
    text-decoration: underline;
}
.line-trough {
    text-decoration: line-through;
}

/*alignment*/
.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

/*vertical alignment*/
.vertical-middle {
    vertical-align: middle;
}

/*titles*/
.section-title {
    font-weight: 500;
    font-size: 90px;
    font-family: $font-secondary;
}

.secondary-title {
    font-weight: 500;
    font-size: 64px;
    font-family: $font-secondary;
}

/*show/hide mobile*/
.show-mobile {
    display: none !important;
}
.show-mobile-block {
    display: none !important;
}

.show-md {
    display: none !important;
}

/*display*/
.block {
    display: block;
}

.inline-block {
    display: inline-block;
}

/*positions*/
.pos-rel {
    position: relative;
}
.pos-abs {
    position: absolute;
}

.z-index {
    z-index: 1;
}
/* Heading Text Sizes */

@each $key,$val in $text-font-sizes {
    .text-#{$key} {
        font-size: #{$val};
    }
}
@each $key,$val in $heading-font-sizes {
    #{$key}, .#{$key} {
        font-size: #{$val};
    }
}

.font-primary {
    font-family: $font-primary;
}

.font-secondary {
    font-family: $font-secondary;
}

/* Text Colors */

@each $key,$val in $main-colors {
    .color-#{$key} {
        color: #{$val};
    }
    .bg-color-#{$key} {
        background-color: #{$val};
    }
}

.italic {
    font-style: italic;
}

.fw-400 {
    font-weight: 400;
}
.fw-500 {
    font-weight: 500;
}
.fw-600 {
    font-weight: 600;
}
.fw-700 {
    font-weight: 700;
}

// Text alignment

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left!important;
}

// Transformation

.text-lowercase {
    text-transform: lowercase;
}
.text-uppercase {
    text-transform: uppercase;
}
.text-capitalize {
    text-transform: capitalize;
}

// Text decorations

.text-underline {
    text-decoration: underline;
}
.no-underline {
    text-decoration: none!important;
}

//truncate
.truncate {
    @include ellipsis(100%);
}


// Width

.w-25 {
    width: 25%;
}

.w-45 {
    width: 45%;
}

.w-50 {
    width: 50%;
}

.w-75 {
    width: 75%;
}

.w-100 {
    width: 100% !important;
}

// Link primary

.link-primary {
    color: $color-text-primary;
    text-decoration: underline;
    transition: all $trsn-dur;

    &:hover {
        opacity: 0.8;
    }
}

.link-underline {
    text-decoration: underline;
}

// cursors

.pointer {
    cursor: pointer;
}

// icons

.icon-xs {
    font-size: 18px;
}

.icon-sm {
    font-size: 21px;
}

.icon-md {
    font-size: 24px;
}

.icon-lg {
    font-size: 48px;
}

.icon-xxl {
    font-size: 120px;
}

/* User image */

.user-image {
    @include bgImage();
    border-radius: $br-circle;
    display: flex;
    align-items: center;
    justify-content: center;

    &.sm {
        width: 48px;
        min-width: 48px;
        height: 48px;
        line-height: 48px;
    }

    &.lg {
        min-width: 120px;
        width: 120px;
        height: 120px;
        line-height: 120px;
    }

    &.uploaded {

        &::after {
            display: none;
        }
    }
}

.avatar-image {
    border-radius: $br-circle;

    &.sm {
        width: 48px;
        min-width: 48px;
        height: 48px;
        line-height: 48px;
        object-fit: cover;
    }

    &.lg {
        min-width: 120px;
        width: 120px;
        height: 120px;
        line-height: 120px;
        object-fit: cover;
    }
}

.shimmer-image {
    background: #f6f7f8 linear-gradient(to right, #f6f7f8 0%, #edeef1 10%, #f6f7f8 40%, #f6f7f8 100%) no-repeat;
    background-size: 800px 120px;
    display: inline-block;
    position: relative;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
    height: 120px;
    width: 120px;
    border-radius: 50%;

    &.lg {
        background-size: 800px 120px;
        height: 120px;
        width: 120px;
    }

    &.sm {
        background-size: 800px 48px;
        height: 48px;
        width: 48px;
        animation-duration: 1.3s;
    }
}

.disabled-content {
    position: relative;
    pointer-events: none;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5);
    }

    &.with-switch {
        .switch {
            position: relative;
            z-index: 1;
            pointer-events: auto;
        }
    }
}

.section-for-student {
    margin: 0 -88px;
    padding: 45px 88px;
    background-color: $color-secondary;
}

.break-word {
    word-break: break-word;
}

/*margins*/
.push-right {
    margin-left: auto;
}
.push-left {
    margin-right: auto;
}

/*borders*/
.border {
    border: 1px solid $color-text-primary-light;
}

.border-top {
    border-top: 1px solid $color-text-primary-light;
}

.border-bottom {
    border-bottom: 1px solid $color-text-primary-light;
}

.border-bottom-light {
    border-bottom: 1px solid $color-border-light;
}

.border-left {
    border-left: 1px solid $color-text-primary-light;
}

.border-right {
    border-right: 1px solid $color-text-primary-light;
}

.border-y {
    border-top: 1px solid $color-text-primary-light;
    border-bottom: 1px solid $color-text-primary-light;
}

.border-x {
    border-left: 1px solid $color-text-primary-light;
    border-right: 1px solid $color-text-primary-light;
}

.no-border-bottom {
    border-bottom: none !important;
}

//lines
.middle-line-light {
    height: 1px;
    background-color: $color-border-light;
}

//maximum width

.max-width-330 {
    max-width: 330px;
}

.max-width-480 {
    max-width: 480px;
}

.max-width-600 {
    max-width: 600px;
}

.max-width-680 {
    max-width: 680px;
}

.form-button-group {
    display: flex;
    justify-content: flex-end;
}

@keyframes placeholderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

/*spaces*/
@each $key-spaces,$val-spaces in $spaces {
    .m-#{$key-spaces} {
        margin: #{$val-spaces};
    }
    .mt-#{$key-spaces} {
        margin-top: #{$val-spaces};
    }
    .mr-#{$key-spaces} {
        margin-right: #{$val-spaces};
    }
    .mb-#{$key-spaces} {
        margin-bottom: #{$val-spaces};
    }
    .ml-#{$key-spaces} {
        margin-left: #{$val-spaces};
    }
    .my-#{$key-spaces} {
        margin-top: #{$val-spaces};
        margin-bottom: #{$val-spaces};
    }
    .mx-#{$key-spaces} {
        margin-left: #{$val-spaces};
        margin-right: #{$val-spaces};
    }

    .p-#{$key-spaces} {
        padding: #{$val-spaces};
    }
    .pt-#{$key-spaces} {
        padding-top: #{$val-spaces};
    }
    .pr-#{$key-spaces} {
        padding-right: #{$val-spaces};
    }
    .pb-#{$key-spaces} {
        padding-bottom: #{$val-spaces};
    }
    .pl-#{$key-spaces} {
        padding-left: #{$val-spaces};
    }
    .py-#{$key-spaces} {
        padding-top: #{$val-spaces};
        padding-bottom: #{$val-spaces};
    }
    .px-#{$key-spaces} {
        padding-left: #{$val-spaces};
        padding-right: #{$val-spaces};
    }
}

.flex-child-half {
    display: flex;

    >* {
        width: 50%;
    }
}
