@media (max-width: $screen-md) {
    .about-page {
        section {
            &:not(.about-banner) {
                padding-top: 40px;
            }
        }
    }
}

@media (max-width: $screen-sm) {
    .founders-inner {
        flex-wrap: wrap;

        .founder {
            flex-basis: 100%;
            padding-bottom: 28px;
        }
    }

    .founder-name {
        text-align: center;
    }

    .section-content {
        .image-with-text {
            flex-wrap: wrap;

            img {
                margin: 0 auto;
                max-width: 100%;
            }

            .text {
                width: 100%;
                text-align: center;
                padding-left: 0;
            }

            &.mobile-reverse {
                padding-top: 28px;
                flex-direction: column-reverse;
            }
        }
    }

    .coach-banner-bg {
        margin: 15px 0 50px;
    }

    .coach-details {
        padding: 50px 0;
        .flex {
            flex-wrap: wrap;

            .coach-details-item {
                width: 100%;

                &:not(:last-child) {
                    padding-bottom: 30px;
                }
            }
        }
    }

    .FAQ-section {
        padding: 50px 0;
    }

    .pricing-content {
        img {
            margin-top: 0;
        }
    }

    .pricing-box {
        padding: 30px 50px;

        h2 {
            padding-bottom: 22px;
        }

        ul {
            padding-bottom: 20px;
        }
    }
}
