/*paths*/
$fonts-folder: "../../fonts";
$img: "../../img";

/*fonts*/
$font-primary: "Gilroy";
$font-secondary: "Calder";
$font-tertiary: "";

/*colors*/
$color-primary: #f9e47e;
$color-primary-hover: #FFDF46;
$color-secondary: #f7f7f7;
$color-secondary-2: #85a0b3;
$color-secondary-3: #526d83;
$color-secondary-4: #9cc9e8;
$color-dark: #222222;
$color-disabled: #CCCCCC;
$color-danger: #FF0000;
$color-danger-light: #FFA8A8;
$color-red: #FA5F56;
$color-warning: #FFF6AF;
$color-warning-dark: #EEC200;
$color-success: #17B429;
$color-border-light: #DAE2E8;

$color-text-primary: #2a496e;
$color-text-primary-light: #8AA0B1;
$color-text-secondary: #ffffff;

$main-colors: (
        primary: $color-primary,
        secondary: $color-secondary,
        secondary-2: $color-secondary-2,
        secondary-3: $color-secondary-3,
        secondary-4: $color-secondary-4,
        success: $color-success,
        text-primary: $color-text-primary,
        text-primary-light: $color-text-primary-light,
        text-secondary: $color-text-secondary,
        disabled: $color-disabled,
        dark: $color-dark,
        danger: $color-danger,
        warning: $color-warning,
        warning-dark: $color-warning-dark
) !default;

/*text sizes*/
$fz-xxs: 12px;
$fz-xs: 14px;
$fz-sm: 15px;
$fz-normal: 16px;
$fz-medium: 18px;
$fz-lg: 20px;

$base-font-size: $fz-medium;

$text-font-sizes: (
        xxs: $fz-xxs,
        xs: $fz-xs,
        sm: $fz-sm,
        normal: $fz-normal,
        medium: $fz-medium,
        lg: $fz-lg,
) !default;

$heading-font-sizes: (
        h6: 16px,
        h5: 18px,
        h4: 26px,
        h3: 32px,
        h2: 44px,
        h1: 130px
) !default;

/*spaces*/
$spaces: (
        0: 0!important,
        1: 4px,
        2: 8px,
        3: 12px,
        4: 16px,
        5: 20px,
        6: 24px,
        7: 28px,
        8: 32px,
        9: 36px,
        10: 40px
) !default;

/*container*/
$cont-width: 1180px;
$cont-width: 1180px;
$cont-padding: 20px;

/*Transitions*/
$trsn-dur: .3s;
$trsn-dur-md: .5s;

/*Border radius */

$br-circle: 50%;

/*Resolutions*/
$screen-lg: 1280px;
$screen-md: 1024px;
$screen-sm: 767px;
$screen-xs: 480px;
$screen-xxs: 360px;

$resolutions: (
        xxs: $screen-xxs,
        xs: $screen-xs,
        sm: $screen-sm,
        md: $screen-md,
        lg: $screen-lg
) !default;

