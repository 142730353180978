.about-page {
    section {
        &:not(.about-banner) {
            padding-top: 90px;
        }

        &:last-child {
            padding-bottom: 90px;
        }
    }
}
.about-banner {
    img {
        width: 100%;
    }
}

.section-content {
    max-width: 800px;
    padding: 0 15px;
    margin: 0 auto;

    &.lg {
        max-width: 960px;
    }

    .image-with-text {
        display: flex;
        align-items: center;

        .image {
            max-width: 300px;
            width: 100%;
            text-align: center;
            margin: 0 auto;
        }

        :not(.image) {
            img {
                max-width: 300px;
            }
        }

        .text {
            padding-left: 44px;
            width: calc(100% - 300px);
        }
    }
}
.founders-inner {
    max-width: 600px;
    display: flex;
    margin: 0 auto;
    padding-top: 65px;
}
.founder {
    cursor: pointer;
    flex: 1;
}
.founder-left {
    margin-right: 16px;
}
.founder-name {
    border-top: 1px solid $color-secondary-2;
    padding-top: 8px;
}
.founder-img {
    text-align: center;
    height: 268px;
    padding-bottom: 28px;

    img {
        height: 100%;
    }
}

.founder-modal {
    .img {
        text-align: center;
        img {
            max-width: 100%;
        }
    }

    .founder-details {
        padding-bottom: 40px;
    }
}

