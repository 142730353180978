.footer {
    background-color: $color-secondary-3;
    color: $color-secondary;
}
.footer-top {
    padding: 50px 0 190px;
}
.footer-top-content {
    display: flex;
    justify-content: space-between;
}
.footer-left {
    display: flex;
}

.footer-bottom {
    padding-bottom: 36px;

    .logo {
        max-width: 94px;

        img {
            width: 100%;
            display: block;
        }
    }
}

.footer-list {
    &:not(:last-child) {
        padding-right: 90px;
    }

    .title {
        padding-bottom: 28px;
    }

    ul {
        color: $color-secondary-4;
        font-size: $fz-xs;
        line-height: 24px;
    }
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.footer-social-list {
    display: flex;

    li {
        &:not(:last-child) {
            margin-right: 34px;
        }

        .icon {
            font-size: 24px;
        }
    }
}
