.FAQ-section {
    padding: 100px 0;
}
.Collapsible {
    border-bottom: 1px solid $color-secondary-2;
}
.Collapsible__trigger {
    padding: 20px 20px 20px 0;
    display: block;
    position: relative;
    cursor: pointer;

    &:after {
        content: '+';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: 16px;
        width: 16px;
        height: 16px;
        line-height: 16px;
        border: 1px solid $color-text-primary;
        border-radius: 2px;
        text-align: center;
    }

    &.is-open {
        &:after {
            content: '-';
            line-height: 14px;
        }
    }
}
.Collapsible__contentInner {
    padding-bottom: 15px;
}
