.btn {
  cursor: pointer;
  display: inline-block;
  font-size: $fz-lg;
  font-weight: 500;
  padding: 11px 5px;
  transition: .3s;
  color: $color-text-primary;
  border-width: 2px;
  border-style: solid;
  line-height: 24px;
  text-align: center;

    &:not(.small) {
        min-width: 170px;
    }

    &.small {
        padding-left: 20px;
        padding-right: 20px;
    }

    &.narrow {
        padding-top: 6px;
        padding-bottom: 6px;
    }

    &.small-text {
        font-size: $fz-xs;
    }

   &.medium-text {
        font-size: $fz-normal;
        line-height: 14px;
    }

    &.w-210 {
        min-width: 210px;
    }

    &.w-190 {
        min-width: 190px;
    }

    &.w-166 {
        min-width: 166px;
    }

    &.w-126 {
        width: 126px;
    }

    &.w-90 {
        min-width: 90px;
    }

    &.no-width {
        min-width: unset;
    }

    &.primary {
        background-color: $color-primary;
        border-color: $color-primary;

        &:hover {
            background-color: $color-primary-hover;
        }

    }

    &.primary-red {
        background-color: $color-red;
        border-color: $color-red;
        color: $color-text-secondary;
    }

    &.primary-text {
      color: blue;
    }


  &.secondary {
        border-color: $color-text-primary;

        &:hover {
            background-color: $color-text-primary;
            color: $color-text-secondary;

            svg, path {
                fill: $color-text-secondary !important;
            }
        }
    }

    &.tertiary {
        background-color: transparent;
        border-color: transparent;
        font-size: $fz-normal;
    }

    &.with-icon {
        display: flex;
        align-items: center;
    }

    &.disabled, &[disabled] {
        background-color: $color-disabled;
        border-color: $color-disabled;
        color: $color-text-secondary;
        pointer-events: none;
    }
}

.btn-link {
    cursor: pointer;
    display: inline-block;
    color: $color-text-primary;
    border: none;
    font-size: $fz-sm;

    &.link-medium {
        font-size: $fz-medium;
        color: $color-text-primary-light;
    }

    &.disabled, &[disabled] {
        color: $color-disabled;
        pointer-events: none;
    }
}

.no-style {
    border: 0;
    padding: 0;
    background-color: transparent !important;
    outline: none;
    font-size: inherit;
    min-width: auto !important;
    color: inherit;
}

.btn-icon {
    cursor: pointer;
    transition: $trsn-dur-md;

    &:hover {
        opacity: 0.7;
    }
}

.btn-row {
    display: flex;
    justify-content: center;
    align-items: center;

    .btn:nth-child(2) {
        margin-left: 40px;
    }
}
