// Checkbox styles

.checkbox {
    cursor: pointer;
    display: inline-flex;

    .check-icon {
        top: 3px;
        width: 16px;
        min-width: 16px;
        height: 16px;
        position: relative;
        background-color: transparent;
        border: 1px solid $color-text-primary;
        border-radius: 4px;

        .icon {
            position: absolute;
            @include XYAlign();
            transition: $trsn-dur;
            opacity: 0;
        }
    }

    .checkbox-label {
        padding-left: 10px;
        transition: $trsn-dur;
        font-size: $fz-sm;
        line-height: 23px;

        &.auth {
            color: $color-text-primary-light;

            font-size: $fz-xs;
        }
    }

    input {
        display: none;

        &:checked {
            & ~ .check-icon {
                opacity: 1;

                .icon {
                    opacity: 1;
                }
            }
        }
    }

    &.disabled {
        pointer-events: none;

        .check-icon {
            border-color: $color-disabled;
        }
        .checkbox-label {
            color: $color-disabled;
        }
    }

    &:hover {
        opacity: 0.8;
    }
}
