.rate-stars {
    [class*='icon'] {
        margin-right: 4px;
        height: 15px;
        width: 15px;
    }

    &.lg {
        [class*='icon'] {
            margin-right: 8px;
            height: 36px;
            width: 36px;
        }
    }

    >span{
        display: flex !important;

        >:last-child {
            [class*='icon'] {
                margin-right: 0;
            }
        }
    }

    &.with-plus {
        padding-right: 15px;
        position: relative;
        &:after {
            content: '+';
            font-size: $fz-sm;
            margin-left: 6px;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
