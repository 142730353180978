.info-banner {
    border-top: 5px solid transparent;
    margin-bottom: 30px;
    position: fixed;
    top: 115px;
    left: 20px;
    right: 20px;
    z-index: 98;
    background-color: transparent;
    color: transparent;

    .icon {
        margin-right: 20px;
    }
    .icon, path {
        fill: transparent !important;
    }

    .info-banner-content {
        display: flex;
        align-items: center;
        padding: 8px 20px;
        justify-content: space-between;
        min-height: 62px;
    }

    .message {
        font-size: 16px;
        padding-right: 10px;
    }

    .content-left {
        display: flex;
        align-items: center;
    }

    &.warning {
        border-color: $color-warning-dark;
        background-color: $color-warning;
        color: $color-text-primary;

        .icon, path {
            fill: $color-warning-dark !important;
        }
    }

    &.success {
        border-color: $color-secondary-3;
        background-color: $color-secondary-4;
        color: $color-text-primary;

        .icon, path {
            fill: $color-secondary-3 !important;
        }
    }

    &.danger {
        border-color: $color-danger;
        background-color: $color-danger-light;
        color: $color-text-primary;

        .icon, path {
            fill: $color-danger !important;
        }
    }
}

.alert-enter {
    opacity: 0;
    transform: translateY(-50%);
}
.alert-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: .3s;
}
.alert-exit {
    opacity: 1;
}
.alert-exit-active {
    opacity: 0;
    transform: translateY(-50%);
    transition: .3s ease-in-out;
}

