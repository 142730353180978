.account-info {

    &.expertise {
        padding-top: 50px;
        border-top: 1px solid $color-text-primary-light;
    }

    .content {
        margin-top: 50px;
        padding-bottom: 50px;
        margin-bottom: 50px;
        border-bottom: 1px solid $color-text-primary-light;

    }

    &:last-child {

        .content {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom-color: transparent;
        }
    }

    .expertise-info {
        width: 100%;
    }



    .review {
        margin-left: 68px;
    }

    .review-comment {
        margin-top: 20px;
        word-break: break-word;
    }
    .reply-comment {
        word-break: break-word;
    }

    .publish-info {
        text-align: right;
    }

    .data-list {
        h3 {
            margin-bottom: 12px;
        }
        & > li {
            margin-bottom: 24px;
            .text-info {
                margin-top: 0;
            }
            &.balance-title {
                margin-bottom: 8px;
            }
        }
    }
}

.profile-editable-list {
    li {
        padding-bottom: 16px;
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;
    }

    .list-item-actions {
        display: flex;
        align-items: center;

        button {
            margin-right: 32px;
        }
    }
}
