.coach-banner-bg {
    height: 205px;
    background: url("../../img/homepage/extra-income.png") no-repeat bottom center;
    margin: 30px 0 100px;
}

.coach-page {
    .coach-details {
        background-color: $color-secondary;
        padding:  80px 100px;

        .coach-details-item {
            width: 30%;

            h4 {
                padding:  16px 0 20px;
            }

            .img {
                height: 230px;
                background: no-repeat bottom center;
            }

            &:nth-child(1) {
                .img {
                    background-image: url("../../img/becomeCoach/detail1.png");
                }
            }
            &:nth-child(2) {
                .img {
                    background-image: url("../../img/becomeCoach/detail2.png");
                }
            }
            &:nth-child(3) {
                .img {
                    background-image: url("../../img/becomeCoach/detail3.png");
                }
            }

        }
    }

    .external-link {
        color: $color-secondary-3;
        font-size: 14px;
        font-weight: 500;
        margin: 50px auto 50px 30px;
        position: relative;
        display: block;
    }
}


