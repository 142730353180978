.header {
    background-color: #fff;
    padding: 29px 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;

    &.sticky {
        transition: box-shadow .3s;
        box-shadow: 1px 1px 6px rgba(0,0,0,.15);
    }

    .logo {
        max-width: 170px;

        img {
            width: 100%;
            display: block;
        }
    }

    .hamburger {
        display: none;
    }

    &.signedIn {
        .header-nav {
            .header-nav-inner {
                flex-direction: row-reverse;
            }
        }
    }

    .notification-btn {
        margin: 0 40px;
        .with-notification {
            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: $color-danger;
            }
            position: relative;
        }
    }



    .dropdown {
        position: relative;


        button {
            cursor: pointer;
        }

        .dropdown-list {
            position: absolute;
            opacity: 0;
            visibility: hidden;
            right: 0;
            top: 100%;
            background-color: #fff;
            box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.15);
            padding: 10px 0;
            border-radius: 6px;
            width: 120px;
            transition: .3s;

            li {
                padding: 0 10px;
                cursor: pointer;
                transition: .3s;

                &:hover {
                    background-color: $color-text-primary;
                    color: $color-text-secondary;
                }
            }
        }

        &.opened {
            .dropdown-list {
                opacity:1;
                visibility: visible;
            }
        }
    }
}

.header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header-nav {


    .header-nav-inner {
        display: flex;
        align-items: center;
    }

    .header-nav-list {
        display: flex;

        >li {
            padding-right: 40px;
            line-height: 30px;

            &.with-border {
                padding-left: 40px;
                border-left: 2px solid $color-text-primary;
            }

            >a {
                font-size: $fz-normal;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: -12px;
                    left: 0;
                    height: 2px;
                    width: 100%;
                    background-color: transparent;
                    pointer-events: none;
                    transition: .3s;
                }

                &:hover {
                    &:after {
                        background-color: $color-text-primary;
                    }
                }
            }

        }


        &.secondary {

            >li {

                &:last-child {
                    border-left: 0;
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
}
