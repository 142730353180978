.banner {
    text-align: center;
    h1 {
        font-family: $font-secondary;
        padding-bottom: 35px;
        font-weight: 400;
    }
}

.banner-content {
    padding: 50px 0 100px;
    position: relative;

    &.logged-in {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-start;

        h1 {
            text-align: left;
            font-size: 90px;
        }

        .banner-img-2 {
            position: static;
        }

        .banner-img-1 {
            right: 0;
            left: initial;
            transform: scaleX(-1);
        }
    }
}

.banner-content-inner {
    max-width: 460px;
    font-size: $fz-lg;
    margin: 0 auto;

    p {
        padding-bottom: 50px;

        span {
            display: block;
            padding-top: 15px;
        }
    }
}

.banner-img-1, .banner-img-2 {
    position: absolute;
    bottom: 0;
    max-width: 315px;

    &.banner-img-1 {
        left: 0;
    }

    &.banner-img-2 {
        right: 0;
    }
}

.search-section {
    background-color: $color-secondary-2;
    padding: 50px 0;
}

.search-content {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-text-secondary;
}

.subject-section {
    padding: 60px 0 100px;
}

.subject-list {
    padding-top: 60px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: 260px;
    gap: 16px 16px;


}

.subject-item {
    a {
        display: block;
        padding: 40px 30px 30px;
        height: 100%;
        position: relative;

        .subject-item-content {
            z-index: 1;
            height: 100%;
            display: flex;
            position: relative;
            flex-direction: column;
            justify-content: space-between;
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 4px;
            background-color: $color-primary;
            transition: height .3s ease-in;
        }

        &:hover {
            &:after {
                height: 100%;
            }
            .subject-item-content {
                text-decoration: underline;
            }
        }
    }

    &:not(.white) {
        background-color: $color-secondary;
    }

    &.white {
        background-color: #fff;
    }

    &.subject-coming-soon {
        position: relative;
        @include selection();

        &::before {
            top: 50%;
            left: 50%;
            z-index: 2;
            display: block;
            font-size: 28px;
            font-weight: 600;
            position: absolute;
            white-space: nowrap;
            content: 'Comming Soon';
            color: $color-text-primary;
            transform: translateX(-50%) translateY(-50%) rotate(-45deg);
        }
        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
            background-color: rgba(#fff, 0.8);
        }

        &::before {
            opacity: 0;
            visibility: hidden;
            transition: all 0.3s;
        }

        a {
            pointer-events: none;
        }

        &:hover {
            &::before {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.testimonial-section {
    padding-bottom: 130px;

    .testimonial-content {
        max-width: 920px;
        margin: 0 auto;
        text-align: center;
    }
    h3 {
        padding-bottom: 60px;
    }
}

.how-works-content {
    background-color: $color-secondary;
    text-align: center;
    padding-top: 34px;
}

.how-works-section {
    padding-bottom: 140px;
}

.support-section {
    background-color: $color-secondary-2;
    text-align: center;
    padding: 55px 0;

    h3 {
        color: $color-secondary;
    }
}

.extra-income {
    padding-bottom: 190px;
    background: url("../../img/homepage/extra-income.png") no-repeat bottom center;
}
