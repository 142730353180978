.__react_component_tooltip {


    &.type-dark {
        max-width: 222px;
        font-size: $fz-xs;
        background: #3E4D56;
        border-radius: 5px;
        padding: 11px 12px;
    }

    &.place-top, &.place-bottom {

        &::after {
            display: none;
        }
    }
}
