.card-number {
  position: relative;

  .c-p-holder {
    background-image: url('../../img/card/card-p-holder.svg');
  }

  .c-icon-visa {
    background-image: url('../../img/card/visa.svg');
  }

  .c-icon-mastercard {
    background-image: url('../../img/card/mastercard.svg');
  }

  .c-icon-american-express {
    background-image: url('../../img/card/amex.svg');
  }

  .c-icon-unionpay {
    background-image: url('../../img/card/unionpay.svg');
  }

  .c-icon-jcb {
    background-image: url('../../img/card/jcb.svg');
  }

  .c-icon-discover {
    background-image: url('../../img/card/discover.svg');
  }

  .c-icon-diners-club {
    background-image: url('../../img/card/diners.svg');
  }

  .c-icon-holder {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 2.536rem;
    height: 1.643rem;
  }
}
