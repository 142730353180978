// radio styles

.radio {
    cursor: pointer;
    display: inline-flex;

    .radio-icon {
        top: 3px;
        width: 16px;
        min-width: 16px;
        height: 16px;
        border-radius: 50%;
        position: relative;
        background-color: transparent;
        border: 1px solid $color-text-primary;

        &::before {
            content: '';
            background-color: $color-text-primary;
            position: absolute;
            height: 10px;
            width: 10px;
            border-radius: 50%;
            font-size: 8px;
            display: block;
            @include XYAlign();
            opacity: 0;
            transition: $trsn-dur;
        }
    }

    .radio-label {
        padding-left: 10px;
        transition: $trsn-dur;
        font-size: $fz-sm;
        line-height: 23px;
    }

    input {
        display: none;

        &:checked {
            & ~ .radio-icon {
                opacity: 1;

                &::before {
                    opacity: 1;
                }
            }
        }
    }

    &.disabled {
        pointer-events: none;

        .radio-icon {
            border-color: $color-disabled;
        }
    }

    &:hover {
        opacity: 0.8;
    }
}
