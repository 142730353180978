.tab-carousel {

    .slick-slide {
        padding: 0 15px;

        .title {
            font-family: $font-secondary;
            font-weight: 400;
        }

        .content-images {
            display: flex;
            justify-content: center;
            padding: 25px 0;

            img {
                width: 100%;
            }
        }
    }



    .slick-dots {
        position: static;
        display: flex;

        li {
            flex: 1;
            height: auto;
            margin: 0;
            padding: 13px;
            font-size: $fz-normal;
            background-color: $color-primary;

            &:not(:last-child) {
                margin-right: 1px;
            }

            &.slick-active {
                background-color: $color-text-primary;
                color: $color-text-secondary;
            }
        }
    }
}
