$selectors: ".5";
.slider-bar-wrapper {
    text-align: center;
}
.slider-bar-text {
    font-size: $fz-normal;
    padding-bottom: 20px;
}
.slider-bar {
    height: 10px;
    width: 100%;
    background-color: $color-text-secondary;
    position: relative;

    .filled {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background-color: $color-primary-hover;
    }
}

.tab-content {
        padding: 50px 88px 100px 88px;
        background-color: #ffffff;
}

.bar-tabs {

    .btn-tab {
        width: 50%;
        line-height: 34px;
        background-color: $color-text-primary;
        color: #ffffff;
        padding: 13px 20px;
        font-weight: 700;
        transition: all $trsn-dur;
        border-top: 2px solid transparent;
        cursor: pointer;

        &.active {
            border-top-color: $color-text-primary;
            background-color: #ffffff;
            color: $color-text-primary;
        }

        &:hover  {
           opacity: 0.8;
        }
    }
}
