.home-select {

    &.subject {
        width: 160px;
        margin: 0 20px 0 10px;
    }

    &.level {
        width: 240px;
        margin-right: 30px;
    }
    .select__control {
        cursor: pointer;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid $color-text-secondary;
        border-radius: 0;
        font-size: 32px;
        font-weight: 700;
        box-shadow: none;

        .select__single-value {
            color: $color-text-secondary;
        }

        //.select__control
    }

    .select__value-container {
        padding-left: 0;
    }

    .select__indicators {
        font-size: 13px;
    }
    .select__indicator, .select__placeholder {
        color: $color-text-secondary;
    }

    &:hover {
        .select__control {
            border-color: $color-primary-hover;
        }
        .select__single-value {
            color: $color-primary-hover;
        }
        .select__indicator {
            color: $color-primary-hover;
        }
        .select__placeholder  {
            color: $color-primary-hover;
        }
    }

    .select__menu-list {
        color: $color-text-primary;
    }
}

.select-box {
    position: relative;

    .select__control {
        border-radius: 0px;
        border-color: $color-secondary-2;
    }

    .select__indicator-separator {
        display: none;
    }

    .select__indicator {

        svg {
            width: 30px;
            height: 30px;
            color: $color-secondary-2;
        }
    }

    .select__clear-indicator {
        display: none;
    }

    &.w-158 {

        .select__control, .select__menu  {
            width: 158px;
        }
    }

    &.has-error {

        .select__control {
            border-color: $color-danger;
        }
    }

    .select__multi-value {
        background-color: rgba($color-secondary-2, .25) ;
        border: 1px solid $color-secondary-2;
        border-radius: 5px;
    }
    .select__multi-value__label {
        font-size: $fz-sm;
        color: $color-text-primary;
        padding: 5px;
    }
    .select__multi-value__remove {
        cursor: pointer;

        .icon {
            font-size: 16px;
        }

        &:hover {
            background-color: initial;
            color: initial;
        }

    }
    .select__input {
        input {
            height: 36px;
        }
    }

    .select-sm {
        .select__dropdown-indicator {
            padding: 4px;
        }
        .select__input {
            input {
                height: 36px;
            }
        }
    }
}

.select-wrapper {
    label {
        display: inline-block;
        padding-bottom: 8px;
    }
}


