$hamburger-padding-x           : 5px;
$hamburger-padding-y           : 0px;
$hamburger-layer-width         : 24px;
$hamburger-layer-height        : 3px;
$hamburger-layer-spacing       : 6px;
$hamburger-layer-color         : $color-text-primary;
$hamburger-layer-border-radius : 2px;
$hamburger-hover-opacity       : 1;
$hamburger-active-layer-color  : $color-text-primary;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;
$hamburger-types     : (slider);
@import "~hamburgers/_sass/hamburgers/hamburgers";

.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
    transition: .3s;
}
