.enhancer-page {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding-bottom: 50px;

    &:not(.with-padding) {
        padding-top: 50px;
    }

    &.with-padding {
        padding-top: 130px;
    }

    .container {
        padding-left: 0;
        padding-right: 0;
    }

    .logo {
        margin-bottom: 42px;
    }

    &.enhancer-large {
        .enhancer-inner {
            max-width: 800px;
            padding: 90px 94px;
        }
    }

    &.countdown-page {
        text-align: center;
        padding: 0 15px;

        .container {
            padding-top: 50px;
            padding-bottom: 50px;
        }
        .enhancer-inner {
            background-color: $color-text-secondary;
            max-width: 800px;
            padding: 0;
        }
    }

    .enhancer-inner {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 96px;
        max-width: 572px;
        padding: 50px 94px;
        background-color: $color-secondary;


        &.large {
            max-width: 800px;
            background-color: $color-text-secondary;
            padding: 0;
        }

        .form-title {
            margin-bottom: 40px;
        }

        .form-subtitle {
            margin-bottom: 50px;
        }

        &.white-bg {
            background-color: $color-text-secondary;
        }

        &.lg {
            max-width: 820px;
        }
        &.margin-sm {
            margin-bottom: 50px;
        }
    }
}

.subject-select-list {
    li {
        border-bottom: 1px solid $color-text-primary-light;
        padding-bottom: 12px;
        margin-bottom: 40px;
        position: relative;
    }

    .abs-remove {
        position: absolute;
        top: 50px;
        right: -50px;
    }
}

.enhancer-footer {

    .footer-nav {
        display: flex;
        align-items: center;
        justify-content: center;

        li {
            &:not(:last-child) {
                margin-right: 7px;
                a {
                    border-right: 1px solid $color-text-primary-light;
                    padding-right: 7px;
                }
            }

            a {
                transition: all $trsn-dur;
                font-size: $fz-xs;
                color: $color-text-primary-light;

                &.disabled {
                    pointer-events: none;
                }

                &:hover {
                    color: $color-text-primary;
                }
            }
        }
    }
}
