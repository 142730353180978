/* Switch styles */

.switch {
    display: inline-block;

    .slider {
        display: inline-block;
        width: 34px;
        height: 14px;
        background-color: #9E9E9E;
        border-radius: 7px;
        position: relative;
        cursor: pointer;
        transition: $trsn-dur;

        &::before {
            content: '';
            width: 20px;
            height: 20px;
            min-width: 20px;
            background-color: #FFFFFF;
            display: inline-block;
            border-radius: $br-circle;
            position: absolute;
            left: 0px;
            @include YAlign();
            transition: all 0.3s;
            box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        }
    }

    input {
        display: none;

        &:checked {

            & ~ .slider {
                background-color: $color-secondary-2;

                &::before {
                    left: 14px;
                    background-color: $color-text-primary;
                }
            }
        }
    }
}
