.small-page {
    background: $color-secondary;


    .container {
        max-width: 1028px;
    }

    .small-page-inner {
        padding: 40px 90px 100px 90px;
        background-color: $color-text-secondary;
    }

    h1 {
        font-size: 44px;
    }
}

.text-page {
    font-weight: 500;

    p {
        font-size: $fz-normal;
        padding-top: 20px;
    }
    h4 {
        padding-top: 50px;
    }
    h6 {
        padding-top: 24px;
    }
    ul {
        li {
            list-style-type: disc;
        }
    }
    ol, ul {
        padding-top: 30px;
        margin-left: 40px;

        li {
            font-size: $fz-normal;
            padding-left: 8px;

            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }
}
