.drawer {
    position: fixed;
    z-index: 1;
    width: 505px;
    top: 115px;
    bottom: 0;
    right: 0;
    background-color: $color-text-secondary;
    border-top: 1px solid $color-text-primary;
    padding: 60px 0 0 40px;
    transform: translateX(100%);
    transition: .3s;

    &.opened {
        transform: translateX(0);
    }

    .btn-icon {
        position: absolute;
        top: 20px;
        right: 20px;

        .icon {
            color: $color-text-primary-light;
        }
    }
}

.drawer-inner-list {
    height: 100%;
    overflow: auto;
    padding-right: 20px;
    padding-bottom: 50px;

    li {
        padding-bottom: 40px;
        border-bottom: 1px solid $color-border-light;

        &:not(:first-child) {
            padding-top: 40px;
        }
    }
}
