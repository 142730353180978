.form-input {
    position: relative;
    margin-bottom: 36px;

    &:last-of-type {
        margin-bottom: 50px;
    }

    &.mb-20 {
        margin-bottom: 20px;
    }

    input, textarea {
        padding: 10px;
        height: 50px;
        line-height: 30px;
        background-color: #ffffff;
        border: 1px solid $color-secondary-2;
        color: $color-dark;
        font-weight: 500;
        font-size: $fz-medium;
        transition: all $trsn-dur;
        width: 100%;

        &.pr-60 {
            padding-right: 60px;
        }

        &.pr-80 {
            padding-right: 80px;
        }

        &:focus {
            border-color: darken( $color-secondary-2, 30% )
        }

        &::placeholder {
            color: $color-disabled;
            font-weight: 400;
            font-size: 17px
        }

    }

    textarea {
        resize: none;
        height: 100%;
        line-height: initial;
    }

    label {
        color: $color-text-primary;
        display: block;
        margin-bottom: 10px;
    }

    .error-message {
        font-size: $fz-xs;
    }

    .info-message {
        font-size: $fz-xxs;
        margin-top: 5px;
        letter-spacing: 0.16px;
        color: $color-secondary-2;
    }

    &.has-error {

        input, textarea {
            border-color: $color-danger;
        }

        .error-message {
            color: $color-danger;

            &:not(.static-message) {
                position: absolute;
                top: 100%;
                left: 0;
            }

        }
    }

    .btn-input {
        cursor: pointer;
        margin-left: 8px;
        width: 30px;
    }

    .input-abs {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
    }

    &.flag-input {
        input {

            padding-left:58px;
        }
    }

    .flag-icon {
        position: absolute;
        top: 1px;
        left: 1px;
        bottom: 1px;
        width: 48px;
        background-color: $color-secondary;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            vertical-align: middle;
            &.card-img {
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                width: 48px;
                height: 28px;
            }
        }
    }
}

.error-message-general {
    position: absolute;
    color: $color-danger;
    font-size: $fz-xs;
    bottom: calc(100% - 20px);
    left: 0;

    &.bottom {
        bottom: 10px;
    }
}

.action-input {
    display: flex;
    align-items: flex-start;

    .btn {
        font-size: $fz-normal;
    }

    &:not(.input-group) {
        .btn {
            margin-left: 16px;
        }
    }


    &.input-group {
        input {
            border-color: transparent;
            flex: 1;

            &:focus {
                border-color: transparent;
            }
        }
    }


}

/*segmented inputs*/

.segmented-input-content {
    margin-bottom: 40px;
    position: relative;


    .error-text {
        position: absolute;
        color: $color-danger;
        font-size: $fz-xs;
        top: 100%;
        left: 0;
        text-align: center;
        width: 100%;
        padding-top: 5px;
    }
}

.segmented-input-list {
    display: flex;
    justify-content: center;

}

.segmented-input {

    &:not(:last-child) {
        margin-right: 20px;
    }


    input {
        width: 50px;
        height: 50px;
        line-height: 50px;
        border: 1px solid $color-text-primary-light;
        font-weight: 700;
        color: $color-dark;
        text-align: center;
        font-size: $fz-medium;

        &:focus {
            border: 2px solid #30496B;
        }
    }
}

input {

    &.no-style {
        background-color: transparent;
        border: none;
        color: $color-text-primary-light;
    }

    &[type="password"] {
        font-weight: 700;
    }
}

.label-inline {
    display: flex;

    label {
        min-width: 165px;
        margin-bottom: 0;
        margin-top: 12px;
    }

    .form-input {
        width: 100%;
    }
}
