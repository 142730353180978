body {
  color: $color-text-primary;
  font-family: $font-primary;
  font-size: $base-font-size;
  line-height: 1.4;
  font-weight: 500;
  height: 100%;

    &.with-layer {
        transition: .3s;
        &:after {
            content: '';
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba($color-text-primary, 0.5);
        }
    }
}

.container {
    max-width: ($cont-width + $cont-padding);
    width: 100%;
    padding-left: ($cont-padding);
    padding-right: ($cont-padding);
    margin-left: auto;
    margin-right: auto;
}
