.search-tutor {
    display: flex;
    align-items: flex-start;

    .main-content {
        width: calc(100% - 190px);
    }

    .fixed-wrapper {
        max-width: 1200px;
        width: 100%;
        padding-left: 216px;
        position: fixed;
        left: 50%;
        bottom: 20%;
        transform: translateX(-50%);
    }
    .fixed-content {
        padding-left: 220px;
        width: 100%;
        position: relative;
    }
    .fixed-button {
        padding: 13px 5px;
        max-width: 466px;
        width: 100%;
        border-radius: 60px;
        box-shadow: 0 0 11px rgba(0, 0, 0, 0.3);
        font-weight: 700;
        font-size: $fz-sm;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}

.filter-bar {
    position: sticky;
    top: 0;
    width: 196px;
    padding-right: 16px;
    padding-bottom: 150px;
    padding-top: 50px;

    .go-back {
        padding-bottom: 50px;
    }

    .list-group-wrapper {
        padding-top: 25px;
        margin-top: 25px;
        border-top: 1px solid $color-secondary-2;
    }
    .list-group {
        li {

        }
    }
    .list-group-title {
        font-size: $fz-sm;
        font-weight: 500;
        padding-bottom: 12px;
    }
}


.result-list {
    padding: 50px 0 150px;
}

.result-list-item {
    padding: 30px;
    background-color: $color-text-secondary;

    &:not(:last-child) {
        border-bottom: 1px solid $color-text-primary-light;
    }


}
.result-item-content {
    padding-left: 70px;
    position: relative;

    .avatar-image {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.result-item-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
}

.result-item-text {
    margin-bottom: 14px;
    height: 75px;
    @include line-clamp(3);
}

.result-item-bottom {
    display: flex;
}

.expertise-list {
    padding-left: 30px;
    display: flex;
    flex-wrap: wrap;

    li {
        color: $color-secondary-2;
        font-size: $fz-normal;
        line-height: 26px;

        .bullet {
            margin-left: 5px;
        }
    }
}

.filter-button {
    height: 50px;
    width: 50px;
    padding: 0;
    line-height: 50px;
    background-color: $color-text-primary;
    color: $color-text-secondary;
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 12;

}
