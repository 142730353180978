.image-upload-wrapper {
    display: flex;
    align-items: center;
    padding-bottom: 50px;
    position: relative;

    .content-right {
        margin-left: 45px;
    }

    .file-name {
        margin-left: 25px;
        flex: 1;
        font-size: $fz-normal;
        word-break: break-all;
    }
}

.image-upload {
    display: inline-block;
    border: 2px solid $color-text-primary;
    font-size: $fz-normal;
    padding: 7px 28px;
    cursor: pointer;

    &:hover {
        background-color: $color-text-primary;
        color: $color-text-secondary;
    }

    input {
        display: none;
    }

    &.disabled {
        background-color: $color-disabled;
        border-color: $color-disabled;
        color: $color-text-secondary;
        pointer-events: none;
    }
}

.uploaded-list {
    li {
        display: flex;
        align-items: center;
        position: relative;

        &:not(:last-child) {
            padding-bottom: 8px;
        }

        .icon-file-image {
            margin-right: 8px;
            min-width: 32px;
        }
        .icon-close {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            cursor: pointer;
        }

        .file-name {
            padding-right: 20px;
        }

        a {
            display: flex;
            align-items: center;
            overflow: visible;
        }
    }
}

